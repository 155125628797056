import service from "@/api/service";
import { SearchRequest } from "@/api/user-profile-shop/request";
import { SearchResponse } from "@/api/user-profile-shop/response";

/**
 * ユーザープロフィール店舗情報一覧・検索APIをコールします。
 *
 * @param searchRequest ユーザープロフィール店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post(
    "/search-user-profile-shop",
    searchRequest
  );
  return response.data as SearchResponse;
}
