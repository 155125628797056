import { Component, Vue } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIDataTable from "@/components/UIDataTable.vue";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import { SearchRequest } from "@/api/user-profile-shop/request";
import { SearchItem } from "@/api/user-profile-shop/response";
import MainShopGet from "@/store/main-shop/get";
import UserProfileShopSearch from "@/store/user-profile-shop/search";
import Flash, { ErrorAlert } from "@/store/common/flash";

@Component({
  components: { UlContentHeader, UlBreadcrumbs, UIDataTable }
})
export default class Index extends Vue {
  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "ユーザープロフィール項目";
  headingSub = "User profile";
  breadCrumbs = [
    { text: "マスターデータ管理", disabled: true },
    { text: "ユーザープロフィール項目", disabled: true },
    { text: "店舗一覧", disabled: true }
  ];
  // テーブルヘッダ（UIDataTableコンポーネントに渡す）
  tableHeaders = [
    { text: "店舗ID", value: "id" },
    { text: "店舗名", value: "name" },
    { text: "", value: "space", sortable: false },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // ------------
  // 変動値
  // ------------

  // ローディングステータス
  isLoading = false;

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    selectShopIds: null as number[] | null
  };

  // テーブル検索オプション（UIDataTableコンポーネントに渡す）
  tableOptions = DEFAULT_TABLE_OPTIONS;

  // ------------

  /**
   * テーブルに表示するアイテムリスト（UIDataTableコンポーネントに渡す）
   */
  get tableItems() {
    return UserProfileShopSearch.getItems;
  }

  /**
   * 総件数（UIDataTableコンポーネントに渡す）
   */
  get totalCount() {
    return UserProfileShopSearch.getTotalCount;
  }

  get shopItems() {
    return MainShopGet.getItems;
  }

  /**
   * createdライフサイクルフック（UIDataTableコンポーネントに渡す）
   */
  async created() {
    await UserProfileShopSearch.restore(this.inputOptions as SearchRequest);
    const request = UserProfileShopSearch.getSearchRequest;
    // 検索入力オプション
    request.searchShopId &&
      (this.inputOptions.searchShopId = request.searchShopId);
    request.selectShopIds &&
      (this.inputOptions.selectShopIds = request.selectShopIds);

    // 検索テーブルオプション
    request.page && (this.tableOptions = request);
    await this.search();
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await MainShopGet.clearResponse();
    await UserProfileShopSearch.clearResponse();
  }

  /**
   * ページング変更コールバック
   *
   * @param tableOptions TableOptions
   */
  async tableChangeCallback(tableOptions: TableOptions) {
    // 子コンポーネントへpagingOptionsの変更が通知される
    this.tableOptions = tableOptions;
    await this.search();
  }

  /**
   * 検索ボタンがクリックされた際のコールバック
   */
  async searchClickCallback() {
    await Flash.clear();
    await this.search();
  }

  /**
   * テーブル行のアクションボタンがクリックされた際のコールバック
   */
  async actionClickCallback() {}

  /**
   * テーブル行の編集ボタンがクリックされた際のコールバック
   *
   * @param item 選択行のAdminItem
   */
  async editClickCallback(item: SearchItem) {
    await this.$router.push({
      name: "user-profile-edit",
      params: { shopId: item.id },
      query: { shopName: item.name }
    });
  }

  /**
   * テーブル行の削除ボタンがクリックされた際のコールバック
   */
  deleteClickCallback() {}

  /**
   * 店舗情報の一覧を取得する処理
   */
  async fetchShopList(): Promise<boolean> {
    if (MainShopGet.isSuccess) {
      return true;
    }

    await MainShopGet.get();
    if (!MainShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: MainShopGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    return MainShopGet.isSuccess;
  }

  /**
   * 検索処理
   */
  async search() {
    this.isLoading = true;
    let isSuccess = await this.fetchShopList();
    if (!isSuccess) {
      this.isLoading = false;
      return;
    }

    let request = this.createRequest();
    await UserProfileShopSearch.search(request);
    if (!UserProfileShopSearch.isSuccess) {
      await Flash.setErrorNow({
        message: UserProfileShopSearch.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.isLoading = false;
  }

  /**
   * dataから検索用のリクエストを作成する
   */
  private createRequest(): SearchRequest {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }

    if (this.inputOptions.selectShopIds) {
      request.selectShopIds = this.inputOptions.selectShopIds;
    } else {
      delete request.selectShopIds;
    }

    return request;
  }
}
